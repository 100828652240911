import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { FiPlay, FiZoomIn, FiFolderPlus } from 'react-icons/fi';

function VideoItem({ galleryItem, myoho }) {
  const { popupLink } = galleryItem;
  const iconHandel = () => {
    if (popupLink) {
      if (popupLink.length > 1) {
        if (popupLink.toString().match(/youtube/g)) {
          return <FiFolderPlus />;
        }
        return <FiFolderPlus />;
      } else if (popupLink.toString().match(/youtube/g)) {
        return <FiPlay />;
      } else {
        return <FiZoomIn />;
      }
    } else {
      return <FiZoomIn />;
    }
  };
  const [toggler, setToggler] = useState(false);
  return (
    <div>
      <div
        className='rn-gallery icon-center video-gallery-content bg_image'
        data-black-overlay='7'
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/${galleryItem.image}.webp)`,
        }}
      >
        <div className='overlay-content'>
          <button
            className='btn-default rounded-player sm-size'
            onClick={() => setToggler(!toggler)}
          >
            <span>{iconHandel()}</span>
          </button>
          <h3 className='title'>
            {myoho
              ? 'Find out more about Myoho Valley'
              : 'Get a glimpse of our  handmade and locally produced products.'}
          </h3>
        </div>
      </div>
      <div className='video-lightbox-wrapper'>
        <FsLightbox toggler={toggler} sources={popupLink} />
      </div>
    </div>
  );
}
export default VideoItem;
