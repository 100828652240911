import React, { Component } from 'react';

class GoogleMapStyle extends Component {
  render() {
    return (
      // Important! Always set the container height explicitly

      <div className='google-map-style-1'>
        <img
          width='100%'
          height='100%'
          style={{ borderRadius: '10px', position: 'relative', border: '0' }}
          src={`./images/timeline/timeline-05.webp`}
        ></img>
      </div>
    );
  }
}

export default GoogleMapStyle;
