import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop';

// Pages import Here
import HomeDefault from './pages/HomeDefault';
import Home from './pages/Home';

// Elements import Here
import Portfolio from './elements/portfolio/Portfolio';
import PortfolioDetails from './pages/PortfolioDetails';
import Team from './elements/team/Team';
import Contact from './elements/contact/Contact';

//import BlogGridView from './components/blog/BlogGridView';
//import BlogListView from './components/blog/BlogListView';
//import BlogGridSidebar from './components/blog/BlogGridSidebar';
//import BlogListSidebar from './components/blog/BlogListSidebar';
//import BlogDetails from './pages/BlogDetails';
import Error from './pages/Error';

// Import Css Here
import './assets/scss/style.scss';
import ApplicationForm from './elements/timeline/ApplicationForm';
import MyohoRegistration from './elements/portfolio/MyohoRegistration';

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>
          <Route
            path={`${process.env.PUBLIC_URL + '/'}`}
            exact
            component={HomeDefault}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/home'}`}
            exact
            component={Home}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/about-us'}`}
            exact
            component={Team}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/workshops'}`}
            exact
            component={Portfolio}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/contact'}`}
            exact
            component={Contact}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/internship-application'}`}
            exact
            component={ApplicationForm}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/myoho-registration'}`}
            exact
            component={MyohoRegistration}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/workshops/:id'}`}
            exact
            component={PortfolioDetails}
          />
          {/* Blog Part  
          <Route
            path={`${process.env.PUBLIC_URL + '/blog-grid'}`}
            exact
            component={BlogGridView}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/blog-list-view'}`}
            exact
            component={BlogListView}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/blog-grid-sidebar'}`}
            exact
            component={BlogGridSidebar}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/blog-list-sidebar'}`}
            exact
            component={BlogListSidebar}
          />
          <Route
            path={`${process.env.PUBLIC_URL + '/blog-details/:id'}`}
            exact
            component={BlogDetails}
          />*/}
          <Route path='*' component={Error} />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
