import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../breadcrumb/BreadcrumbOne';
import SectionTitle from '../sectionTitle/SectionTitle';
import PortfolioOne from './PortfolioOne';

const Portfolio = () => {
  return (
    <>
      <SEO
        title='Workshops in Central Portugal on natural construction including hempcrete, fireproofing, isolation, clay, and more.'
        url='https://crystalcanna.com/workshops'
      />
      <Layout>
        <BreadcrumbOne
          title=''
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Workshops'
        />

        <div className='main-content' style={{ padding: '0 11px' }}>
          <div className='rwt-portfolio-area' style={{ marginTop: '25px' }}>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Ongoing & Planned'
                    title='Workshops'
                    description=''
                  />
                </div>
              </div>
              <PortfolioOne Column='col-lg-6 col-md-6 col-sm-12 col-12 mt--30 portfolio' />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Portfolio;
