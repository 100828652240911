import React from 'react';
import ContactForm from './ContactForm';
import GoogleMapStyle from './GoogleMapStyle';
import { FiFacebook, FiMail, FiInstagram } from 'react-icons/fi';

const ContactOne = () => {
  return (
    <>
      <div className='row row--15'>
        <div className='col-lg-12'>
          <div className='rn-contact-address mt_dec--30'>
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-12'>
                <div className='rn-address'>
                  <div className='icon'>
                    <FiMail />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Email Address</h4>
                    <p>
                      <a href='mailto:pawel@crystalcanna.com'>
                        pawel@crystalcanna.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-12'>
                <div className='rn-address'>
                  <div className='icon'>
                    <FiInstagram />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Instagram</h4>
                    <a href='https://www.instagram.com/crystal.canna/'>
                      <p>@crystal.canna</p>
                    </a>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6 col-12'>
                <div className='rn-address'>
                  <div className='icon'>
                    <FiFacebook />
                  </div>
                  <div className='inner'>
                    <h4 className='title'>Facebook</h4>
                    <p>
                      <a href='https://www.facebook.com/criystalcanna/'>
                        @criystalcanna
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mt--40 row--15'>
        <div className='col-lg-7'>
          <ContactForm formStyle='contact-form-1' />
        </div>
        <div className='col-lg-5 mt_md--30 mt_sm--30'>
          <GoogleMapStyle />
        </div>
      </div>
    </>
  );
};
export default ContactOne;
