import React from 'react';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import SEO from '../common/SEO';
import HeaderOne from '../common/header/HeaderOne';
//import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterOne from '../common/footer/FooterOne';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import PortfolioOne from '../elements/portfolio/PortfolioOne';
import Separator from '../elements/separator/Separator';
import TimelineOne from '../elements/timeline/TimelineOne';
import VideoItem from '../elements/video/VideoItem';
import TestimonialThree from '../elements/testimonial/TestimonialThree';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
//import BlogList from '../components/blog/itemProp/BlogList';
//import BlogClassicData from '../data/blog/BlogList.json';
//var BlogListData = BlogClassicData.slice(0, 3);

const PopupData = [
  {
    id: '01',
    image: 'images/bg/video-thumbnail-crystalcanna',
    popupLink: ['https://www.youtube.com/watch?v=OeZASiMzDlw'],
  },
];

const HomeDefault = () => {
  return (
    <>
      <SEO
        title='Crystal Canna | Natural Building Workshops Portugal'
        url='https://crystalcanna.com/'
      />
      <main className='page-wrapper'>
        {/*   <HeaderTopNews /> */}
        <HeaderOne
          btnStyle='btn-small round btn-icon'
          HeaderSTyle='header-default'
        />

        {/* Start Slider Area  */}
        <div
          className='slider-area slider-style-1 variation-default height-850 bg_image'
          data-black-overlay='3'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-03.webp)`,
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner text-center'>
                  <span
                    className='subtitle'
                    style={{ textTransform: 'uppercase', letterSpacing: '2px' }}
                  >
                    {' '}
                    Healing Community in Central Portugal
                  </span>
                  <h1 className='title display-one'>Crystal Canna.</h1>
                  <p className='description'>
                    Because we all have the right to Nature!
                  </p>
                  <div className='button-group'>
                    <a
                      className='btn-default btn-medium round btn-icon'
                      href='#projects'
                    >
                      UPCOMING WORKSHOPS{' '}
                      <i className='icon'>
                        <FiArrowRight />
                      </i>
                    </a>
                    <Link
                      className='btn-default btn-medium btn-border round btn-icon'
                      to='/contact'
                    >
                      Contact Us{' '}
                      <i className='icon'>
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Slider Area  

        <div
          className='slider-area slider-style-1 variation-default bg_image'
          data-black-overlay='3'
          style={{
            height: 'auto',
            display: 'flex',
            alignItems: 'start',
          }}
        >
          <video
            width='100%'
            loop
            autoPlay
            muted
            style={{
              zIndex: 1,
            }}
          >
            <source
              src={`${process.env.PUBLIC_URL}/images/bg/community_portugal_intro.m4v`}
              type='video/mp4'
            />
          </video>

          <div
            className='container'
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <div className='row' style={{ zIndex: 2 }}>
              <div className='col-lg-12'>
                <div className='inner text-center'>
                  <span className='subtitle'>
                    Healing Community in Central Portugal
                  </span>
                  <h1 className='title display-one'>
                    Crystal <span>Canna</span>
                  </h1>
                  <p className='description'>
                    BECAUSE WE ALL HAVE THE RIGHT TO NATURE!
                  </p>
                  <div className='button-group'>
                    <a
                      className='btn-default btn-medium round btn-icon'
                      href='#projects'
                    >
                      Upcoming Workshops{' '}
                      <i className='icon'>
                        <FiArrowRight />
                      </i>
                    </a>
                    <Link
                      className='btn-default btn-medium btn-border round btn-icon'
                      to='/contact'
                    >
                      Contact Us{' '}
                      <i className='icon'>
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
         End Slider Area  */}

        {/* Start Service Area  */}
        <div className='rn-service-area rn-section-gapBottom pt--100'>
          <div className='container'>
            <ServiceOne
              serviceStyle='service__style--1 icon-circle-style'
              textAlign='text-center'
            />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />
        {/* Start Portfolio Area  */}
        <div id='projects' className='rwt-portfolio-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Ongoing & Planned'
                  title='Workshops'
                  description=''
                />
              </div>
            </div>
            <PortfolioOne Column='col-lg-4 col-md-6 col-12 mt--30 portfolio' />
          </div>
        </div>
        {/* End Portfolio Area  */}

        <Separator />
        {/* Start Timeline Area  */}
        <div className='rwt-timeline-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Opportunities to Learn'
                  title='Interning.'
                  description='Are you driven and do you desire to be part of longterm collaboration growing our healing community? <br /> We offer 6 and 12-month long internships with the possibility of employment afterwards.'
                />
              </div>
            </div>
            <div className='row mt_dec--20'>
              <div className='col-lg-12'>
                <TimelineOne />
              </div>
            </div>
          </div>
        </div>
        {/* End Timeline Area  */}

        {/* Start Video Area  */}
        <div className='rwt-video-area rn-section-gapBottom'>
          <div className='container'>
            <div className='row'>
              {PopupData.map((item) => (
                <div className='col-lg-12' key={item.id}>
                  <VideoItem galleryItem={item} />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Video Area  */}

        <Separator />

        {/* Start Elements Area  */}
        <div className='rwt-testimonial-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--20'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Experiences'
                  title='Testimonials.'
                  description=''
                />
              </div>
            </div>
            <TestimonialThree teamStyle='' />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* BLOG FUTURE
        <Separator />
        <div className='blog-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Latests News'
                  title='Our Latest News.'
                  description='We provide company and finance service for <br /> startups and company business.'
                />
              </div>
            </div>
            <div className='row row--15'>
              {BlogListData.map((item) => (
                <div
                  key={item.id}
                  className='col-lg-4 col-md-6 col-sm-12 col-12 mt--30'
                >
                  <BlogList StyleVar='box-card-style-default' data={item} />
                </div>
              ))}
            </div>
          </div>
        </div> */}

        {/* Start Elements Area  */}
        <div className='rwt-callto-action-area rn-section-gapBottom'>
          <div className='wrapper'>
            <CalltoActionFive />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterOne />
        <Copyright />
      </main>
    </>
  );
};
export default HomeDefault;
