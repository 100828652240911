import React from 'react';
import Typed from 'react-typed';

const AboutOne = () => {
  return (
    <div className='rwt-about-area rn-section-gap'>
      <div className='container'>
        <div className='row row--30 align-items-center'>
          <div className='col-lg-5'>
            <div className='thumbnail'>
              <img
                className='w-100'
                src='./images/about/about-1.webp'
                alt='About Images'
              />
            </div>
          </div>

          <div className='col-lg-7 mt_md--40 mt_sm--40'>
            <div className='content'>
              <div className='section-title'>
                <h2 className='title'>
                  About our Community <br />{' '}
                  <Typed
                    className='theme-gradient'
                    strings={['Healing.', 'Growing.', 'Building.']}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  />
                </h2>
                <p>
                  Our journey began with the destructive fire of October 2017
                  raging through Central Portugal destroying everything on its
                  path. In search restorative solutions for the soil, hemp
                  became the first choice for regenerative cash crop. Hemp
                  proved to be able to restore balance in the ecosytem as well
                  as provide immediate income for the farm.
                </p>
                <p>
                  Since then we dove deep into all the healing qualites of this
                  wonderful plant ranging from CBD and it's effects on our
                  endocanabinoid system to hempcrete as perfect building
                  material in a fire prone environment. Currently our efforts
                  are focused on promoting the widespread benefits of the hemp
                  plant and fighting for its liberation from the monopoly of the
                  pharmaceutical industry.
                </p>
                <p>
                  We have numerous projects runnning ranging from permaculture
                  to natural construction to mushroom cultivation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOne;
