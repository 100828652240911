import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import SectionTitle from '../sectionTitle/SectionTitle';
import TeamOne from './TeamOne';
import BreadcrumbOne from '../breadcrumb/BreadcrumbOne';
import AboutOne from '../about/AboutOne';

const Team = () => {
  return (
    <>
      <SEO
        title='About our Team of Experts in natural construction workshops Central Portugal'
        url='https://crystalcanna.com/about-us'
      />
      <Layout>
        <BreadcrumbOne
          title=''
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Team'
        />
        <div className='main-content' style={{ padding: '0 11px' }}>
          {/* Start About Area  */}
          <AboutOne />
          {/* End About Area  */}

          {/* Start Elements Area  */}
          <div className='rwt-team-area' style={{ marginTop: '25px' }}>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Experts.'
                    title='Our Team.'
                    description=''
                  />
                </div>
              </div>
              <TeamOne
                column='col-lg-4 col-md-6 col-12 mt--30'
                teamStyle='team-style-default'
              />
            </div>
          </div>
          {/* End Elements Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Team;
