import React from 'react';
import { FiHeart, FiTarget, FiLoader } from 'react-icons/fi';
import ScrollAnimation from 'react-animate-on-scroll';

const ServiceList = [
  {
    icon: <FiHeart />,
    title: 'Harmony',
    description:
      'We are pioneering ways to minimalise negative impact on our surrounding ecosystem, using locally sourced materials and develop a more harmonious relationship with Mother Earth.',
  },
  {
    icon: <FiTarget />,
    title: 'Sustainability',
    description:
      'We are making sure that we are leaving the planet a better place than we found it with future generations at Heart, that is why permaculture and regenerative farming are at the core of all our practices.',
  },
  {
    icon: <FiLoader />,
    title: 'Spiritual Growth',
    description:
      'We are dedicated to providing a space for growth and spiritual development, for all those on the path of remembering the truth within.',
  },
];

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className='row row--15 service-wrapper'>
      {ServiceList.map((val, i) => (
        <div className='col-lg-4 col-md-6 col-sm-6 col-12' key={i}>
          <ScrollAnimation
            animateIn='fadeInUp'
            animateOut='fadeInOut'
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className='icon'>{val.icon}</div>
              <div className='content'>
                <h4
                  style={{ textTransform: 'uppercase', letterSpacing: '2px' }}
                  className='title w-600'
                  dangerouslySetInnerHTML={{ __html: val.title }}
                ></h4>
                <p
                  className='description b1 color-white mb--0'
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
