import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import SectionTitle from '../sectionTitle/SectionTitle';
import BreadcrumbOne from '../breadcrumb/BreadcrumbOne';

const MyohoRegistration = () => {
  return (
    <>
      <SEO
        title='Myoho Valley Workshop Registration'
        url='https://crystalcanna.com/myoho-registration'
      />
      <Layout>
        <BreadcrumbOne
          title=''
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Myoho Registration Form'
        />
        <div className='main-content' style={{ padding: '0 11px' }}>
          {/* Start Contact Area  */}
          <div className='rwt-contact-area' style={{ marginTop: '25px' }}>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 mb--40'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Myoho Valley Workshop'
                    title='Registration Form'
                    description=''
                  />
                </div>
              </div>
              <iframe
                class='airtable-embed'
                src='https://airtable.com/embed/appoDwhD0sHy1dIro/pagVX8kfWUgjp0Y9w/form'
                frameborder='0'
                onmousewheel=''
                width='100%'
                height='533'
                style={{ background: 'transparent', border: '1px solid #ccc' }}
              ></iframe>
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default MyohoRegistration;
