import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import Separator from '../../elements/separator/Separator';
import ScrollAnimation from 'react-animate-on-scroll';

const teamData = [
  {
    image: 'team-01',
    name: 'Pawel',
    designation: 'CEO',
    location: 'Founder of CrystalCanna & Co-Founder of LusiCanna',
    description: '',
    socialNetwork: [],
  },
  {
    image: 'team-02',
    name: 'Miguel',
    designation: 'Construction Expert',
    location:
      'Founder of CannaCasa, Co-Founder of LusiCanna & Founder of CannaHabitat',
    description: '',
    socialNetwork: [],
  },
  {
    image: 'team-03',
    name: 'Nicole',
    designation: 'Operations Coordinator',
    location: 'Happiness Manager',
    description: '',
    socialNetwork: [],
  },
  {
    image: 'team-04',
    name: 'Become part of the Team!',
    designation: 'Apply Below',
    location: 'Is this going to be you?',
    description: '',
    socialNetwork: [],
  },
];

const TeamOne = ({ column, teamStyle }) => {
  return (
    <div className='row row--15' style={{ justifyContent: 'center' }}>
      {teamData.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`rn-team ${teamStyle}`}>
            <ScrollAnimation
              animateIn='fadeInUp'
              animateOut='fadeInOut'
              animateOnce={true}
            >
              <div className='inner'>
                <figure className='thumbnail'>
                  <img src={`./images/team/${data.image}.webp`} alt='' />
                </figure>
                <figcaption className='content'>
                  <h2 className='title'>{data.name}</h2>
                  <h6 className='subtitle theme-gradient'>
                    {data.designation}
                  </h6>
                  <span className='team-form'>
                    <span className='location'>{data.location}</span>
                  </span>
                  <p className='description'>{data.description}</p>

                  <ul className='social-icon social-default icon-naked mt--20'>
                    {data.socialNetwork.map((social, index) => (
                      <li key={index}>
                        <a target='_blank' href={`${social.url}`}>
                          {social.icon}
                        </a>
                      </li>
                    ))}
                  </ul>
                </figcaption>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      ))}
      <Separator />
      {/* Start Elements Area  */}

      <div className='wrapper'>
        <div className='rn-callto-action clltoaction-style-default style-5'>
          <div className='container'>
            <div className='row row--0 align-items-center content-wrapper theme-shape'>
              <div className='col-lg-12'>
                <div className='inner'>
                  <div className='content text-center'>
                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <h2 className='title'>
                        Become part of the Crystal Canna Family
                      </h2>
                    </ScrollAnimation>
                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <h6 className='subtitle'>
                        Do you think you have important skills to add to the
                        team and grow our healing community together, then
                        please don't hesitate to reach out. We would love to
                        have a chat!
                      </h6>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <div className='call-to-btn text-center'>
                        <a className='btn-default btn-icon' href='/contact'>
                          GET IN TOUCH
                          <i className='icon'>
                            <FiArrowRight />
                          </i>
                        </a>
                      </div>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End Elements Area  */}
    </div>
  );
};

export default TeamOne;
