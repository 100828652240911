import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import SectionTitle from '../sectionTitle/SectionTitle';
import ContactOne from './ContactOne';
import BreadcrumbOne from '../breadcrumb/BreadcrumbOne';

const Contact = () => {
  return (
    <>
      <SEO
        title='Contact us to know more about our natural construction & mushroom cultivation projects.'
        url='https://crystalcanna.com/contact'
      />
      <Layout>
        <BreadcrumbOne
          title=''
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Contact'
        />
        <div className='main-content' style={{ padding: '0 11px' }}>
          {/* Start Contact Area  */}
          <div className='rwt-contact-area' style={{ marginTop: '25px' }}>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 mb--40'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Lets get connected'
                    title='Contact Details'
                    description=''
                  />
                </div>
              </div>
              <ContactOne />
            </div>
          </div>
          {/* End Contact Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Contact;
