import React from 'react';
import BreadcrumbOne from '../../elements/breadcrumb/BreadcrumbOne';
import VideoItem from '../../elements/video/VideoItem';

const PortfolioDetailsContent = ({ data }) => {
  const PopupDataMyoho = [
    {
      id: '02',
      image: 'images/bg/bg-image-myoho',
      popupLink: ['https://youtu.be/X5tGJVU__NM'],
    },
  ];

  return (
    <div className='rwt-projects'>
      <div className='container'>
        <BreadcrumbOne
          title=''
          rootUrl='/workshops'
          parentUrl='Workshops'
          currentUrl={data.title}
        />
        <br />
        <div className='row'>
          <div className='col-lg-10 offset-lg-1'>
            <div className='inner'>
              <div className='details-list'>
                <div className='thumbnail alignwide'>
                  <img
                    className='radius w-100'
                    src={`${process.env.PUBLIC_URL}/${data.largeImage}`}
                    alt='project image'
                  />
                </div>

                <div className='row mt--40 row--30'>
                  <div className='col-lg-6'>
                    <div className='content-left'>
                      <h4 className='title'>{data.title}</h4>
                      <div className='single-list-wrapper'>
                        <div className='single-list'>
                          <label>Date:</label>
                          <span>{data.date}</span>
                        </div>
                        <div className='single-list'>
                          <label>Target Group:</label>
                          <span>{data.client}</span>
                        </div>
                        <div className='single-list'>
                          <label>Number of Participants:</label>
                          <span>{data.participants}</span>
                        </div>
                        <div className='single-list'>
                          <label>Language:</label>
                          <span>{data.language}</span>
                        </div>

                        <div className='single-list'>
                          <label>Category:</label>
                          <span>{data.category}</span>
                        </div>
                      </div>
                      <div className='view-btn mt--50'>
                        <a
                          className='btn-default btn-large round'
                          href={`${data.btn.link}`}
                        >
                          {data.btn.buttontext}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6 mt_md--30 mt_sm--30'>
                    <div className='content-right'>
                      <h5 className='subtitle'>{data.subtitle}</h5>
                      <div
                        className='description'
                        dangerouslySetInnerHTML={{ __html: data.body }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='portfolio-gallery mt--40'>
                <div className='gallery mt--30'>
                  <div className='thumbnail'>
                    <img
                      className='radius w-100'
                      src={`${process.env.PUBLIC_URL}/${data.gallery.imageOne}`}
                      alt='project image'
                    />
                  </div>
                </div>
                <div className='gallery mt--30'>
                  <div className='thumbnail'>
                    <img
                      className='radius w-100'
                      src={`${process.env.PUBLIC_URL}/${data.gallery.imageTwo}`}
                      alt='project image'
                    />
                  </div>
                </div>
                <div className='gallery mt--30'>
                  <div className='thumbnail'>
                    <img
                      className='radius w-100'
                      src={`${process.env.PUBLIC_URL}/${data.gallery.imageThree}`}
                      alt='project image'
                    />
                  </div>
                </div>
                {data.video === 'yes' && (
                  <div className='gallery mt--30'>
                    <div className='rwt-video-area rn-section-gapBottom'>
                      <div className='container'>
                        <div className='row'>
                          {PopupDataMyoho.map((item) => (
                            <div className='col-lg-12' key={item.id}>
                              <VideoItem galleryItem={item} myoho={'yes'} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioDetailsContent;
