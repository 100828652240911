import React from 'react';
import Slider from 'react-slick';
import { slickDot } from '../../utils/script';

const TestimonialData = [
  {
    form: 'Portugal',
    description:
      'Crystal Canna is a place where magic happens. If you look for a sustainable and eco-friendly kind of experience this is the right place. The environment and the people around you transform the hard work into something fun and groovy :) Teamwork and community spirit is a plus in this farm. I loved the experience. I learn everyday something new! And I made friends for life -- I feel so grateful for all the experience!!!.',
    name: 'Vera Patrícia',
    subtitle: 'May 2021',
    image: 'testimonial-dark-02',
  },
  {
    form: 'United States',
    description:
      'Thank you Pawel for creating such a powerful, meditative, and magical presence on the land you occupy. My days were never boring and I adored the energy of everyone I interacted with during my stay. I cannot recommend this place enough. I learned so much each day well beyond just taking care of hemp and other agricultural practices. I hope to see you all again someday soon, may the universe continue protect and surprise you.',
    name: 'Lindsay',
    subtitle: 'January 2022',
    image: 'testimonial-dark-01',
  },
  {
    form: 'Germany',
    description:
      'I truly had a wonderful time with Pawel and the crew! It was winter so I mostly helped with the preparation of the hemp, but there is so much to do outside in the summer as well. I had a lot of time to practice yoga and meditation and these guys really allowed me to take all the space I need for being quiet. I just made some amazing new friends and have nothing but gratitude for this experience.',
    name: 'Anna-Lea',
    subtitle: 'December 2020',
    image: 'testimonial-dark-03',
  },
  {
    form: 'Germany',
    description:
      'Pawel and his team/friends were happily welcoming as soon as I arrived. They introduced me to their peaceful daily routine including healthy original meals made of fresh local products, yoga and meditation sessions, swimming in the near by river... the area is full of nature with beautiful hilly.',
    name: 'Adjuna Martina',
    subtitle: 'July 2020',
    image: 'testimonial-dark-04',
  },
  {
    form: 'Portugal',
    description:
      'Thanks for get know you all happy people and lovely dog, making lemon jam, go for swimming, meditate together, making music together, see you soon. Big hugs.',
    name: 'Matthieu',
    subtitle: 'August 2020',
    image: 'testimonial-dark-05',
  },
];
const TestimonialThree = ({ teamStyle }) => {
  return (
    <div className='row'>
      <div className='col-lg-12'>
        <Slider
          className='slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60'
          {...slickDot}
        >
          {TestimonialData.map((data, index) => (
            <div key={index} className={`testimonial-style-two ${teamStyle}`}>
              <div className='row align-items-center row--20'>
                <div className='order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1'>
                  <div className='content mt_sm--40'>
                    <span className='form'>{data.form}</span>
                    <p className='description'>{data.description}</p>
                    <div className='client-info'>
                      <h4 className='title'>{data.name}</h4>
                      <h6 className='subtitle'>{data.subtitle}</h6>
                    </div>
                  </div>
                </div>
                <div className='order-1 order-md-2 col-lg-4 col-md-4'>
                  <div className='thumbnail'>
                    <img
                      className='w-100'
                      src={`./images/testimonial/${data.image}.webp`}
                      alt='Corporate React Template'
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};
export default TestimonialThree;
