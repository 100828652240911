import PropTypes from 'prop-types';
import React from 'react';

const SEO = ({ title, url }) => {
  return (
    <>
      <meta charSet='utf-8' />
      <title>{title} || Healing Community in Central Portugal</title>
      <meta name='robots' content='noindex, follow' />
      <meta
        name='description'
        content='Join us for a natural building workshop in Central Portugal and learn building with natural materials including hempcrete, lime, stone, wood, clay, cob and more in a sustainable way from start to finish.'
      />
      <meta
        property='og:title'
        content='Crystal Canna Healing Community in Central Portugal'
      />
      <meta
        property='og:description'
        content='Join us for a natural building workshop in Central Portugal and learn building with natural materials including hempcrete, lime, stone, wood, clay, cob and more in a sustainable way from start to finish.'
      />
      <meta
        property='og:image'
        content={`${process.env.PUBLIC_URL}/images/logo/logo-green.webp`}
      />
      <meta property='og:url' content={url} />
      <link rel='canonical' href={url} />
      <meta property='og:site_name' content='CrystalCanna.com' />
      <meta property='og:locale' content='en_US' />
      <meta property='og:type' content='website' />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, shrink-to-fit=no'
      />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
