import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';

const TimelineData = [
  {
    id: '1',
    date: 'Option 1',
    title: 'Natural Construction.',
    description:
      'If you feel drawn to natural building techniques and you wish to develop your skills.',
    image: 'timeline-01',
    workingStep: [
      {
        stepTitle: 'Requirements',
        stepDescription: 'Woodworking Experience.',
      },
      {
        stepTitle: 'Skills to gain',
        stepDescription:
          'Building with cob, hempcrete, strawbale, natural plasters, and stone masonary.',
      },
    ],
  },
  {
    id: '2',
    date: 'Option 2',
    title: 'Mycology.',
    description:
      'If mushrooms are your passion and you desire to deepen your practical skills, lets grow together.',
    image: 'timeline-03',
    workingStep: [
      {
        stepTitle: 'Requirements',
        stepDescription:
          'Degree in mycology in desired but not essential. Previous mycology experience.',
      },
      {
        stepTitle: 'Skills to gain',
        stepDescription:
          'Complete mushroom cultivation, starting from the lab all the way to the field.',
      },
    ],
  },
  {
    id: '3',
    date: 'Option 3',
    title: 'Permaculture.',
    description:
      'Permaculture and regenerative farming are the guiding principles in caring for our community gardens.',
    image: 'timeline-04',
    workingStep: [
      {
        stepTitle: 'Requirements',
        stepDescription:
          'Previous permaculture experience. Be physically fit. Willingness to put knowledge into practice.',
      },
      {
        stepTitle: 'Skills to gain',
        stepDescription:
          'Deepening your knowledge on permaculture principles, including companion planting, syntropic agriculture, food forestry, and animal care.',
      },
    ],
  },
];

const TimelineOne = () => {
  return (
    <div className='rn-timeline-wrapper timeline-style-one position-relative'>
      <div className='timeline-line'></div>
      {/* Start Single Timeline  */}

      {TimelineData.map((data, index) => (
        <div className='single-timeline mt--50' key={index}>
          <div className='timeline-dot'>
            <div className='time-line-circle'></div>
          </div>
          <div className='single-content'>
            <div className='inner'>
              <div className='row row--30 align-items-center'>
                <div className='order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40'>
                  <div className='content'>
                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <span className='date-of-timeline'>{data.date}</span>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <h2 className='title'>{data.title}</h2>
                    </ScrollAnimation>

                    <ScrollAnimation
                      animateIn='fadeInUp'
                      animateOut='fadeInOut'
                      animateOnce={true}
                    >
                      <p className='description'>{data.description}</p>
                    </ScrollAnimation>

                    <div className='row row--30'>
                      {data.workingStep.map((data, index) => (
                        <div className='col-lg-6 col-md-6 col-12' key={index}>
                          <div className='working-list'>
                            <ScrollAnimation
                              animateIn='fadeInUp'
                              animateOut='fadeInOut'
                              animateOnce={true}
                            >
                              <h5 className='working-title'>
                                {data.stepTitle}
                              </h5>
                            </ScrollAnimation>

                            <ScrollAnimation
                              animateIn='fadeInUp'
                              animateOut='fadeInOut'
                              animateOnce={true}
                            >
                              <p>{data.stepDescription}</p>
                            </ScrollAnimation>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className='read-morebtn'>
                      <ScrollAnimation
                        animateIn='fadeInUp'
                        animateOut='fadeInOut'
                        animateOnce={true}
                      >
                        <Link
                          className='btn-default btn-large round'
                          to='/internship-application'
                        >
                          <span>APPLY</span>
                        </Link>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
                <div className='order-1 order-lg-2 col-lg-6'>
                  <div className='thumbnail'>
                    <img
                      className='w-100'
                      src={`./images/timeline/${data.image}.webp`}
                      alt='Crystal Canna | Interning Options'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* End Single Timeline  */}
    </div>
  );
};

export default TimelineOne;
